<template>
  <div>
    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" @click="openModalAgregar()" v-if="$agregar(['Empresas'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'plus', }" class="icon btn-icon" /> NUEVA EMPRESA
          </a>
        </transition>
      </div>
    </div>
    <div class="row">
      <transition name="slide-fade" appear>
        <div class="col-12">
          <div class="table-responsive site-table2-responsive">
            <vue-good-table
            styleClass="vgt-table condensed site-table2"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              trigger: 'enter',
              skipDiacritics: true,
              placeholder: 'Buscar empresa',
            }">
              <div slot="emptystate">
                <div class="empty-image">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin datos</p>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'Acciones'">
                  <b-button class="btn-detail mr-1" title="Editar empresa" v-b-tooltip.hover.bottom.noninteractive
                    @click="openModalEditar(props.row)"
                    v-if="$editar(['Empresas'])"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'edit', }" class="icon btn-icon" /></b-button>
                  <b-button class="btn-detail" title="Eliminar empresa" v-b-tooltip.hover.bottom.noninteractive
                    @click="openModalEliminar(props.row)"
                    v-if="$eliminar(['Empresas'])"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon" /></b-button>
                </span>
                <span v-else-if="props.column.field == 'AplicaRepse'">
                  {{ props.row.AplicaRepse == '1' ? 'SI' : 'NO' }}
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </transition>
    </div>
    <b-modal
      id="bv-modal-empresas" size="md" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$agregar(['Empresas']) || $editar(['Empresas'])">
      <template #modal-title>
        {{ action === 'add' ? 'NUEVO' : 'EDITAR' }} EMPRESA
      </template>
      <form class="form-site inner" @submit.prevent="submit()">
        <div class="form-row form-row-select">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('nombre')}">
            <fieldset>
              <legend>Empresa</legend>
              <input type="text" class="form-control" placeholder="Nombre de la empresa" v-model="nombre">
              <span class="icon" :title="validation.firstError('nombre')" v-if="validation.hasError('nombre')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('alias')}">
            <fieldset>
              <legend>Alias</legend>
              <input type="text" class="form-control" placeholder="Alias de la empresa" v-model="alias">
              <span class="icon" :title="validation.firstError('alias')" v-if="validation.hasError('alias')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('rfc')}">
            <fieldset>
              <legend>RFC</legend>
              <input type="text" class="form-control" placeholder="RFC" v-model="rfc">
              <span class="icon" :title="validation.firstError('rfc')" v-if="validation.hasError('rfc')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('aplicarepse') ? 'select-error' : ''">
              <legend>Aplica REPSE</legend>
              <v-select
                class="form-select"
                placeholder="Empresa con subcontratistas"
                label="label"
                :options="[{ label: 'SI', value: 1 }, { label: 'NO', value: 0 }]"
                :reduce="(option) => option.value"
                :searchable="false"
                :clearable="false"
                v-model="aplicarepse">
                <template v-slot:option="option">
                    <b-badge class="font-size-14" :variant="option.value === 1 ? 'success' : 'secondary'">{{ option.label }}</b-badge>
                </template>
                <template #selected-option="{ label, value }">
                  <b-badge class="font-size-14" :variant="value === 1 ? 'success' : 'secondary'">{{ label }}</b-badge>
                </template>
              </v-select>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Guardar
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    VueGoodTable,
  },
  data() {
    return {
      loading: false,
      action: '',
      empresaId: 0,
      nombre: '',
      rfc: '',
      alias: '',
      aplicarepse: '',
      columns: [
        {
          label: '#',
          field: 'EmpresaId',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Nombre',
          field: 'Nombre',
        },
        {
          label: 'Alias',
          field: 'Alias',
        },
        {
          label: 'RFC',
          field: 'Rfc',
        },
        {
          label: 'Para REPSE',
          field: 'AplicaRepse',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Acciones',
          field: 'Acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '150px',
        },
      ],
      rows: [],
    };
  },
  validators: {
    nombre(value) { return Validator.value(value).required('Campo obligatorio').lengthBetween(5, 100, 'Longitud de 5 a 100 caracteres'); },
    alias(value) { return Validator.value(value).required('Campo obligatorio').lengthBetween(1, 20, 'Longitud de 1 a 20 caracteres'); },
    rfc(value) { return Validator.value(value).required('Campo requerido').lengthBetween(12, 13, 'Formato de 12 o 13 caracteres'); },
    aplicarepse(value) { return Validator.value(value).required('Campo requerido').between(0, 1, 'Valor incorrecto'); },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      apiClient.get('/empresas')
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {});
    },
    openModalAgregar() {
      this.nombre = '';
      this.rfc = '';
      this.alias = '';
      this.aplicarepse = 1;
      this.action = 'add';
      this.$bvModal.show('bv-modal-empresas');
    },
    openModalEditar(row) {
      this.empresaId = row.EmpresaId;
      this.nombre = row.Nombre;
      this.rfc = row.Rfc;
      this.alias = row.Alias;
      this.aplicarepse = row.AplicaRepse;
      this.action = 'edit';
      this.$bvModal.show('bv-modal-empresas');
    },
    openModalEliminar(row) {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminará la Empresa seleccionado.', {
        title: 'Eliminar Empresa',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) {
            this.empresaId = row.EmpresaId;
            this.action = 'delete';
            this.submit();
          }
        })
        .catch(() => {});
    },
    submit() {
      if (this.action === 'add' || this.action === 'edit') {
        this.$validate().then((success) => {
          if (success) {
            this.loading = true;
            if (this.action === 'add') {
              apiClient.post('/empresas', {
                nombre: this.nombre,
                rfc: this.rfc,
                alias: this.alias,
                aplicarepse: this.aplicarepse,
              })
                .then(() => {
                  this.getData();
                  this.$bvModal.hide('bv-modal-empresas');
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
            if (this.action === 'edit') {
              apiClient.put('/empresas', {
                empresaId: this.empresaId,
                nombre: this.nombre,
                rfc: this.rfc,
                alias: this.alias,
                aplicarepse: this.aplicarepse,
              })
                .then(() => {
                  this.getData();
                  this.$bvModal.hide('bv-modal-empresas');
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          }
        });
      }
      if (this.action === 'delete') {
        if (this.action === 'delete') {
          apiClient.delete('/empresas', {
            data: {
              empresaId: this.empresaId,
            },
          })
            .then(() => {
              this.getData();
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
